var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-text"},[_c('span',{staticClass:"mx-1 pointer float-right",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1),_c('div',{staticClass:"pre-line mb-2"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"text-warning"},[_c('small',[_vm._v("ราคา: "+_vm._s(_vm.item.salesPrice)+" บาท / "+_vm._s(_vm.item.unit.name))])]),(_vm.item.account)?_c('div',{staticClass:"text-success"},[_c('small',[_vm._v("บัญชี: "+_vm._s(_vm.item.account.code)+" ("+_vm._s(_vm.item.account.name)+")")])]):_vm._e()])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"ชื่อ","validations":[
            {text: 'required!', value: _vm.$v.formData.name.$dirty && !_vm.$v.formData.name.required}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-currency',{staticClass:"col-6",attrs:{"label":"ราคา/หน่วย","validations":[
            {text: 'required!', value: _vm.$v.formData.salesPrice.$dirty && !_vm.$v.formData.salesPrice.required}
          ]},model:{value:(_vm.formData.salesPrice),callback:function ($$v) {_vm.$set(_vm.formData, "salesPrice", $$v)},expression:"formData.salesPrice"}}),_c('DocConfigFormUnit',{staticClass:"col-6",attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"label":"หน่วย","select":"id","validations":[
            {text: 'required!', value: _vm.$v.formData.unitId.$dirty && !_vm.$v.formData.unitId.required}
          ]},model:{value:(_vm.formData.unitId),callback:function ($$v) {_vm.$set(_vm.formData, "unitId", $$v)},expression:"formData.unitId"}}),_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"label":"บัญชี","select":"id","validations":[
            {text: 'required!', value: _vm.$v.formData.accountId.$dirty && !_vm.$v.formData.accountId.required}
          ]},model:{value:(_vm.formData.accountId),callback:function ($$v) {_vm.$set(_vm.formData, "accountId", $$v)},expression:"formData.accountId"}})],1),(_vm.isForm)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.toggle)?_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"type":"button"},on:{"click":_vm.cancelData}},[_vm._v(" ยกเลิก ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-danger ml-2 float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyData(_vm.item.id)}}},[_vm._v(" ลบ ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }