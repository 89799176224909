<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="">
          {{item.inventory.code}} ({{item.inventory.name}})
        </div>
        <small>
          <div class="pre-line my-2 text-primary">
            {{item.name}}
          </div>
          <div>
            ราคา: {{item.salesPrice}} บาท
          </div>
          <div
            v-if="alternativeSalesPrice.length > 0"
            class="text-warning">
            ราคาขายใหม่:
            <ul class="my-0">
              <li
                v-for="child in alternativeSalesPrice"
                :key="child.id">
                {{child.salesPrice}} บาท ---
                {{child.contact.code}} ({{child.contact.name}})
              </li>
            </ul>
          </div>
          <div
            v-if="converter.length > 0"
            class="text-info">
            แปลงหน่วย:
            <ul class="my-0">
              <li
                v-for="child in converter"
                :key="child.id">
                {{child.name}} ({{child.converter}})
              </li>
            </ul>
          </div>
          <div
            v-if="costOfSales.length > 0"
            class="text-danger">
            ต้นทุนขาย:
            <ul class="my-0">
              <li
                v-for="child in costOfSales"
                :key="child.id">
                {{child.account.code}} ({{child.account.name}})
              </li>
            </ul>
          </div>
          <div
            v-if="sellAccountReceivable.length > 0"
            class="text-success">
            ขาย:
            <ul class="my-0">
              <li
                v-for="child in sellAccountReceivable"
                :key="child.id">
                {{child.account.code}} ({{child.account.name}})
              </li>
            </ul>
          </div>
        </small>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-textarea
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-textarea>

          <DocConfigFormInventory
            :docType="docType"
            :templateType="templateType"
            ref="inventory"
            class="col-12"
            select="id"
            v-model="formData.inventoryId"
            :types="menu.inventoryTypes"
            :validations="[
              {text: 'required!', value: $v.formData.inventoryId.$dirty && !$v.formData.inventoryId.required}
            ]">
          </DocConfigFormInventory>

          <sgv-input-currency
            class="col-12"
            label="ราคาขาย"
            v-model="formData.salesPrice">
          </sgv-input-currency>
        </div>

        <template v-if="item.id">
          <ul class="nav nav-pills justify-content-center mb-3">
            <li
              class="nav-item"
              v-for="tab in tabs"
              :key="tab.value"
              @click.prevent="selectedTab = tab.value">
              <a
                class="nav-link"
                href="#"
                :class="{'active': selectedTab === tab.value}">
                {{tab.text}}
              </a>
            </li>
          </ul>

          <div v-if="selectedTab === 'alternativeSalesPrice'">
            <div class="form-row">
              <DocConfigFormContact
                :docType="docType"
                :templateType="templateType"
                ref="contact"
                class="col-12 col-sm-8"
                select="id"
                :types="menu.include.alternativeSalesPrice.contactTypes"
                v-model="alternativeSalesPriceFormData.contactId"
                :validations="[
                  {text: 'required!', value: $v.alternativeSalesPriceFormData.contactId.$dirty && !$v.alternativeSalesPriceFormData.contactId.required}
                ]">
              </DocConfigFormContact>

              <sgv-input-text
                label="ราคาขายใหม่"
                class="col-12 col-sm-4"
                placeholder="100 หรือ +3%, -5%"
                v-model="alternativeSalesPriceFormData.salesPrice"
                :validations="[
                  {text: 'required!', value: $v.alternativeSalesPriceFormData.salesPrice.$dirty && !$v.alternativeSalesPriceFormData.salesPrice.required}
                ]">
              </sgv-input-text>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addAlternativeSalesPrice">
              เพิ่ม
            </button>

            <div
              v-if="alternativeSalesPrice.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in alternativeSalesPrice"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    {{child.salesPrice}} บาท ---
                    {{child.contact.code}} ({{child.contact.name}})
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'converter'">
            <div class="form-row">
              <sgv-input-text
                class="col-12 col-sm-6"
                label="ชื่อ"
                placeholder="ตรม."
                v-model="converterFormData.name"
                :validations="[
                  {text: 'required!', value: $v.converterFormData.name.$dirty && $v.converterFormData.name.$error}
                ]">
              </sgv-input-text>

              <sgv-input-text
                class="col-12 col-sm-6"
                label="อัตราส่วน"
                placeholder="+1.25 หรือ -0.5 หรือ 10"
                v-model="converterFormData.converter"
                :validations="[
                  {text: 'required!', value: $v.converterFormData.converter.$dirty && $v.converterFormData.converter.$error}
                ]">
              </sgv-input-text>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addConverter">
              เพิ่ม
            </button>

            <div
              v-if="converter.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in converter"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    {{child.name}} ({{child.converter}})
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'costOfSales'">
            <div class="form-row">
              <DocConfigFormAccount
                label="ต้นทุนขาย"
                class="col-12"
                select="id"
                :docType="docType"
                :templateType="templateType"
                v-model="costOfSalesFormData.accountId"
                :validations="[
                  {text: 'required!', value: $v.costOfSalesFormData.accountId.$dirty && $v.costOfSalesFormData.accountId.$error}
                ]">
              </DocConfigFormAccount>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addCostOfSales">
              เพิ่ม
            </button>

            <div
              v-if="costOfSales.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in costOfSales"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    {{child.account.code}} ({{child.account.name}})
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'sellAccountReceivable'">
            <div class="form-row">
              <DocConfigFormAccount
                label="ต้นทุนขาย"
                class="col-12"
                select="id"
                :docType="docType"
                :templateType="templateType"
                v-model="sellAccountReceivableFormData.accountId"
                :validations="[
                  {text: 'required!', value: $v.sellAccountReceivableFormData.accountId.$dirty && $v.sellAccountReceivableFormData.accountId.$error}
                ]">
              </DocConfigFormAccount>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addSellAccountReceivable">
              เพิ่ม
            </button>

            <div
              v-if="sellAccountReceivable.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in sellAccountReceivable"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    {{child.account.code}} ({{child.account.name}})
                  </li>
                </ul>
              </small>
            </div>
          </div>
        </template>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    contact: {
      type: Object,
      required: false
    },
    inventory: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        inventoryId: null,
        salesPrice: 0,
      },

      tabs: [
        {text: 'ราคาเฉพาะ', value: 'alternativeSalesPrice'},
        {text: 'แปลงหน่วย', value: 'converter'},
        {text: 'บัญชีต้นทุนขาย', value: 'costOfSales'},
        {text: 'บัญชีขาย', value: 'sellAccountReceivable'},
      ],
      selectedTab: 'alternativeSalesPrice',

      alternativeSalesPriceFormData: {
        type: 'alternativeSalesPrice',
        parentId: this.item.id,
        contactId: null,
        salesPrice: ''
      },
      converterFormData: {
        type: 'converter',
        parentId: this.item.id,
        name: '',
        converter: ''
      },
      costOfSalesFormData: {
        type: 'costOfSales',
        parentId: this.item.id,
        accountId: null
      },
      sellAccountReceivableFormData: {
        type: 'sellAccountReceivable',
        parentId: this.item.id,
        accountId: null
      },
    }
  },
  validations: {
    formData: {
      name: {required},
      inventoryId: {required},
    },
    alternativeSalesPriceFormData: {
      contactId: {required},
      salesPrice: {required},
    },
    converterFormData: {
      name: {required},
      converter: {required},
    },
    costOfSalesFormData: {
      accountId: {required}
    },
    sellAccountReceivableFormData: {
      accountId: {required}
    }
  },
  computed: {
    costOfSales () {
      return this.item.children.filter(v => v.type === 'costOfSales')
    },
    sellAccountReceivable () {
      return this.item.children.filter(v => v.type === 'sellAccountReceivable')
    },
    alternativeSalesPrice () {
      return this.item.children.filter(v => v.type === 'alternativeSalesPrice')
    },
    converter () {
      return this.item.children.filter(v => v.type === 'converter')
    },
  },
  methods: {
    serializeInput (formData) {
      return {
        type: formData.type,
        name: formData.name,
        inventoryId: formData.inventoryId,
        salesPrice: formData.salesPrice.toString(),
      }
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    addAlternativeSalesPrice () {
      this.$v.alternativeSalesPriceFormData.$touch()
      if (this.$v.alternativeSalesPriceFormData.$invalid) return

      this.$emit('child', {
        input: this.alternativeSalesPriceFormData,
        cb: () => {
          this.toggle = true
          this.$v.alternativeSalesPriceFormData.$reset()
        }
      })
    },
    addConverter () {
      this.$v.converterFormData.$touch()
      if (this.$v.converterFormData.$invalid) return

      this.$emit('child', {
        input: this.converterFormData,
        cb: () => {
          this.toggle = true
          this.$v.converterFormData.$reset()
        }
      })
    },
    addCostOfSales () {
      this.$v.costOfSalesFormData.$touch()
      if (this.$v.costOfSalesFormData.$invalid) return

      this.$emit('child', {
        input: this.costOfSalesFormData,
        cb: () => {
          this.toggle = true
          this.$v.costOfSalesFormData.$reset()
        }
      })
    },
    addSellAccountReceivable () {
      this.$v.sellAccountReceivableFormData.$touch()
      if (this.$v.sellAccountReceivableFormData.$invalid) return

      this.$emit('child', {
        input: this.sellAccountReceivableFormData,
        cb: () => {
          this.toggle = true
          this.$v.sellAccountReceivableFormData.$reset()
        }
      })
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.salesPrice = this.item.salesPrice

          this.formData.inventoryId = this.item.inventory.id
          this.$refs.inventory.setGroup(this.item.inventory.type)
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.inventory) {
            this.formData.inventoryId = this.inventory.id
            this.$refs.inventory.setGroup(this.inventory.type)
          }
        })
      } else {
        this.formData.name = ''
        this.formData.salesPrice = 0
        this.formData.inventoryId = null
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormInventory,
    DocConfigFormAccount,
    DocConfigFormContact
  }
}
</script>

<style lang="css" scoped>
</style>
